<template>
	<div>
		<f-default-header
			:title="$t('pages.inventory.title')"
			:subtitle="$t('pages.inventory.missingData.subtitle')" />

		<f-missing-totals />

		<f-actions-btn-holder>
			<v-btn elevation="0" large @click="cancel">
				{{ $t("labels.btnCancel") }}
			</v-btn>
			<v-btn color="primary" elevation="0" large @click="save">
				{{ $t("labels.btnContinue") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import FMissingTotals from "../../components/inventory/MissingTotals.vue";
import NumberFormarter from "../../helpers/number_formater";

export default {
	components: {
		FMissingTotals,
	},
	methods: {
		async save() {
			const result = await this.$store.dispatch("inventory/adjustMissingData");

			if (!result) {
				this.$store.dispatch("dialog/show", {
					title: this.$t("labels.error"),
					content: this.$t("alerts.posInsertValues"),
					confirm: true,
				});
				return;
			} else {
				this.$router.push({
					name: "inventory-preview",
				});
			}
		},
		cancel() {
			this.$router.push({
				name: "inventory-upload",
			});
		},
	},
};
</script>
