<template>
  <div>
    <!-- weeee aloha
        {{content}} -->
    <v-row>
      <v-col cols="6">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formatedDate"
              :label="$t('labels.selectMonth')"
              prepend-icon="mdi-calendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            />
          </template>

          <v-date-picker v-model="date" type="month" scrollable>
            <v-spacer />

            <v-btn text color="secondary" @click="modal = false">
              {{ $t('labels.btnCancel') }}
            </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined
          dense
          label="Total"
          v-mask="currencyMask"
          v-model="price"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import DateFormater from "../../helpers/date_formater";

var currencyMask = createNumberMask({
  prefix: "R$ ",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 9,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowNegative: false,
  requireDecimal: false,
});

export default {
  props: ["value"],
  data: () => ({
    currencyMask,
    content: null,
    date: DateFormater.lastMonthDate(),
    modal: false,
    price: "",
  }),
  created() {
    this.date = this.$route.params.date;
    this.content = this.value;
  },
  computed: {
    formatedDate() {
      var [year, month] = this.date.split("-");
      return this.labels(`${month}${year}`);
    },
  },
  watch: {
    date() {
      this.update();
    },
    price() {
      this.update();
    },
  },
  methods: {
    labels(value) {
      const month = DateFormater.monthNumberToString(value.slice(0, 2));
      return `${month}/${value.slice(2)}`;
    },
    update() {
      this.content = {
        date: this.date,
        value: this.price,
      };
      this.$emit("input", this.content);
    },
  },
};
</script>
